import './form.css'
import HubspotContactForm from '../../components/HubspotContactForm/HubspotContactForm';
import LogoVendingSVC from '../../assets/img/LogoVendingSVC.svg'
import { Link } from 'react-router-dom';
import flechaNaranja from '../../assets/img/flechaNaranja.svg'

function CrearCuentaForm() {
  return (
    <main>
      <div className='form-header mt-5 d-md-none'>
        <Link className="d-flex align-items-center justify-content-center logo-container" to="/">
          <img alt="Logo" src={LogoVendingSVC} /><span className="ms-2 svc-logo-font">SVC</span>
        </Link>
        <p className="svc-text-font mt-4">
          Crea hoy mismo tu cuenta, para solicitar
          <br/>
          tu negocio. <b>Empieza a rentabilizar 
          <br/>  
          tu tiempo y dinero.</b>
        </p>
      </div>
      <div className="form-container">
      <div className='form-header-desktop text-center'>
        <Link className="logo-container" to="/">
          <img alt="Logo" src={LogoVendingSVC}/>
          <p className="svc-logo-font">SVC</p>
        </Link>
        <p className="svc-text-font mt-4 mb-5">
          Crea hoy mismo tu cuenta, para solicitar tu negocio.
          <br/>
          <b>Empieza a rentabilizar tu tiempo y dinero.</b>
        </p>
      </div>
        <div className="container">
          <HubspotContactForm 
            region="na1"
            portalId="21901438"
            formId='d5468e9b-d3b3-49d0-94cf-8084aff7b203'
          />
        </div> 
      <div className="form-footer mt-2">
      Al crear tu cuenta, estás aceptando los <span className='tc-font'>términos y condiciones</span>
      <br/>
      de Southern Vending Company - SVC
      </div>
      <div className='text-center mt-5'>
        <Link className='link-element-orange' to="/"><img alt="flecha-volver" src={flechaNaranja} className="me-2" />Volver al inicio</Link>
      </div>
      </div>
    </main> 
  );
}

export default CrearCuentaForm;