import { useState } from 'react';
import './calculator.css'
import CalculatorTable from "./CalculatorTable";

function Calculator() {

    const [vDiarias, setVDiarias] = useState();
    const [nMaquinas, setNMaquinas] = useState();
    const [rEsperada, setREsperada] = useState();
    const [cData, setCData] = useState({
        vDiarias: false, nMaquinas: false, rEsperada: false
    });

    function handleClick() {
        setCData({
            vDiarias: vDiarias, nMaquinas: nMaquinas, rEsperada: rEsperada
        });
    }

    function resetInput(inputType) {
        if(inputType == "rEsperada"){
            document.getElementById("vDiarias").value = "";
            document.getElementById("nMaquinas").value = "";
            setVDiarias(false);
            setNMaquinas(false);
        } else {
            document.getElementById("rEsperada").value = "";
            setREsperada(false);
        }
    }

    return (
        <div className="Calculator">
            <div className="d-lg-flex justify-content-evenly text-center mt-5">
                <p className="input-calculator-font">
                    Ventas diarias
                    <br/>
                    <input onChange={(e) => setVDiarias(e.target.value)} type={"number"} className="input-calculator" onClick={()=>resetInput()} id="vDiarias" min={0}/>
                </p>
                <p className="input-calculator-font">
                    N° máquinas
                    <br/>
                    <input onChange={(e) => {setNMaquinas(e.target.value)}} type={"number"} className="input-calculator" id="nMaquinas" onClick={()=>resetInput()} min={0}/>
                </p>
            </div>
            <div className="my-5">
                <h2><span>O</span></h2>
            </div>
            <div className="text-center">
                <p className="input-calculator-font">
                    Rentabilidad esperada
                    <br/>
                    <input onChange={(e) => {setREsperada(e.target.value)}} type={"number"} className="input-calculator" id="rEsperada" onClick={()=>resetInput("rEsperada")} min={0}/>
                </p>
                <button onClick={() => handleClick()} type="button" className="btn btn-calculator px-3">Calcular</button>
            </div>
            <div className="calculator-container">
                <CalculatorTable vDiarias={cData.vDiarias} nMaquinas={cData.nMaquinas} rEsperada={cData.rEsperada}/>
            </div>
        </div>
    );
}

export default Calculator;