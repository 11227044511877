import React from "react";

class HubspotContactForm extends React.Component {
  componentDidMount() {
    const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/embed/v2.js';
        const style = document.createElement('style');
        style.type = 'text/css';
        style.rel = "stylesheet"
        style.innerHTML = `
        @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
        @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
        .grecaptcha-badge {
            display: none;
        }
        span {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            color: #969A9D;
        }
        span:after{
            color:yellow;
        }
        .hs-form-field:focus-within span{
            z-index:-1;
        }
        .hs-form-required {
            color: #969A9D;
        }
        .hubspot-link__container.sproket {
            display: none;
        }
        h1.hsform-header-text {
            color: black;
            font-family: 'Roboto';
            font-size: 36pt;
            line-height: 36pt;
        }
        @media (max-width:798px){
            h1.hsform-header-text{
                font-size: 30pt;
            }
        }
        .hs-input:not([type=file]) {
            background-color: white;
        }
        .hs-form-field input[type=text], .hs-form-field input[type=email], .hs-form-field input[type=phone], .hs-form-field input[type=number], .hs-form-field input[type=tel], .hs-form-field input[type=date], .hs-form-field select, .hs-form-field textarea {
            border: 0.5px solid #ABAFB3;
            border-radius: 15px;
        }
        .actions {
            display: flex;
            justify-content: center;
        }
        input.hs-button {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 17px;
            line-height: 21px;
            color: #FFFFFF;
            background-color: #FE5E41;
            border-radius: 5rem;
            margin-top: 35px;
            border-radius: 50px;
            border: none;
            width: 25%;
            min-width: 256px;
            height:50px;
            cursor: pointer;
            transition: background-color 0.25s;
            box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 25%);
        }
        input.hs-button:active {
            box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 35%);
        }
        input.hs-button:hover {
            background-color: #FE5E41;
        }
        input.hs-button:active {
            background-color: #FE5E41;
            border: 5px solid #FE5E41;
        }
        input.hs-button:focus {
            background-color: #FE5E41;
            border-color: #FE5E41;
        }
        .submitted-message.hs-main-font-element {
            height: 100%;
            justify-content: space-evenly;
            display: flex;
            flex-direction: column;
        }
        .submitted-message.hs-main-font-element>p {
            display: flex;
            font-size: 16pt;
            font-family: "Roboto";
            color: darkgray;
            flex-direction: column;
            width: 80%;
        }
        .submitted-message.hs-main-font-element>p::before {
            content: "Gracias ";
            color: #FE5E41;
            font-size: 10em;
            font-weight: 600;
            font-family: 'Poppins';
            text-align: center;
        }
        .submitted-message {
            color: #FE5E41;
            font-size: 3em;
            font-weight: 600;
            font-family: 'Poppins';
            text-align: center;
        }
        `
  
        document.body.appendChild(script);
        //document.body.appendChild(style);
  
        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                const form = window.hbspt.forms.create({
                    ...this.props,
                    target: '#hubspotForm'
                });
                form.onReady((e) => {
                  e.parentElement.parentElement.parentElement.firstChild.appendChild(style)
                  console.log(e.parentElement.parentElement.parentElement);
                });
                
            }
        });
  }

  render() {
    return (
      <div id="hubspotForm" />
    );
  }
}

export default HubspotContactForm;