import './home.css'
import vending from '../../assets/img/vending.svg';
import step1 from '../../assets/img/step1.svg'
import step2 from '../../assets/img/step2.svg'
import step3 from '../../assets/img/step3.svg'
import step4 from '../../assets/img/step4.svg'
import textfade from '../../assets/img/textfade.svg'
import money from '../../assets/img/money.svg'
import projections from '../../assets/img/projections.svg'
import bodegas from '../../assets/img/bodegas.svg'
import Calculator from '../../components/Calculator/Calculator';
import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/Navbar';
import { useNavigate } from 'react-router-dom';

function Home() {

  const navigate = useNavigate();

  return (
  <>
    <Navbar/>
    <div className="Home">
      <div className="container">
        <h1 className="home-title">Rentabiliza tu tiempo y dinero</h1>
        <p className="home-subtitle d-none d-lg-block">
          Genera una fuente de ingreso adicional de manera
          <br/>
          fácil, rápida y segura con la <b>Vending Machine Nº1 de Japón.</b>
        </p>
        <p className="home-subtitle d-lg-none">
          Genera una fuente de ingreso adicional de manera
          fácil, rápida y segura con la <b>Vending Machine Nº1 de Japón.</b>
        </p>
        <div className="text-center">
          <button className="btn btn-home-orange-i" onClick={()=>navigate("form-solicitar")}>Solicita tu Máquina</button>
        </div>
        <div className="text-center mt-5">
          <img className="img-vending img-fluid" src={vending} alt="maquina expendedora"/>
        </div>
        <h1 className="second-title" id="comoFunciona">Abre tu negocio así de simple</h1>
        <div className="d-lg-flex justify-content-lg-around text-center mt-5">
          <div className="mt-lg-5">
            <img alt="Logo" className="img-fluid" src={step1}/>
            <p className="p-step">Crea tu cuenta</p>
          </div>
          <div className="">
          <p className="p-step d-none d-lg-block">Adjunta tus documentos</p>
            <img alt="paso 2" className="img-fluid" src={step2}/>
            <p className="p-step d-lg-none">Adjunta tus documentos</p>
          </div>
          <div className="mt-lg-4">
            <img  alt="paso 3" className="img-fluid" src={step3}/>
            <p className="p-step">Firma tu contrato</p>
          </div>
          <div className="">
            <p className="p-step-4 mt-4 mb-0 d-none d-lg-block">Recibe tu Máquina</p>
            <img alt="paso 4" className="img-fluid" src={step4}/>
            <p className="p-step d-lg-none">Recibe tu Máquina</p>
          </div>
        </div>
        <div className="text-center mt-4">
          <button className="btn btn-home-orange2" onClick={()=>navigate("form-solicitar")}>Crea tu cuenta</button>
        </div>
        <h1 className="second-title" id='rentabilidad'>¿Cuanto quieres ganar al mes?</h1>
        <p className="home-subtitle">
          Puedes ingresar las ventas diarias estimadas y Nº de máquinas,
          <br/>
          <b>o partir de la rentabilidad esperada.</b>
        </p>
        <Calculator/>
        <h1 className="second-title">
          Solo preocúpate de reponer tu máquina,
          <br/> 
          nosotros de todo lo demás
        </h1>
        <div className="font-fade-container text-center mt-5">
          <img alt="beneficios" className="img-fluid" src={textfade}/>
        </div>
        <div className="money-container text-center">
          <img alt="dinero" className="img-fluid" src={money}/>
        </div>
        <div className="text-center mt-3">
          <img alt="proyecciones" className="img-fluid projections-container" src={projections}/>
        </div>
        <div className="text-center mt-4">
          <button className="btn btn-home-orange3" onClick={()=>navigate("form-solicitar")}>Crea tu cuenta</button>
        </div>
        <h1 className="second-title" id='beneficiosEmpresa'>
          QUIERO ESTE BENEFICIO
          <br/>
          EN MI LUGAR DE TRABAJO
        </h1>
        <p className="home-subtitle mt-4">
          Si eres dueño de una empresa, o estas encargado del bienestar de tu empresa
          <br/>
          o eres un colaborador y quieres contar con esta <b>maquina vending completamente GRATIS</b>
        </p>
        <div className="text-center mt-5">
          <button className="btn btn-home-orange3" onClick={()=>navigate("form-empresa")}>Solícitalo aquí</button>
        </div>
        <div className="bodegas-container text-center">
          <img alt="bodegas" className="img-fluid" src={bodegas}/>
        </div>
      </div>
      <Footer/>
    </div>
  </>
  );
}

export default Home;