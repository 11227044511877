import './form.css'
import HubspotContactForm from '../../components/HubspotContactForm/HubspotContactForm';
import LogoVendingSVC from '../../assets/img/LogoVendingSVC.svg'
import { Link } from 'react-router-dom';
import flechaNaranja from '../../assets/img/flechaNaranja.svg'

function SolicitarForm() {
  return (
    <main>
      <div className='form-header mt-5 d-md-none'>
        <Link className="d-flex align-items-center justify-content-center logo-container" to="/">
          <img alt="Logo" src={LogoVendingSVC} /><span className="ms-2 svc-logo-font">SVC</span>
        </Link>
        <p className="svc-text-font mt-4">
        <b>Solicita hoy mismo</b> una Máquina vending
        <br/>
        para que todos los colaboradores puedan
        <br/>
        disfrutar de los mejores productos.
        </p>
      </div>
      <div className="form-container">
      <div className='form-header-desktop text-center'>
        <Link className="logo-container" to="/">
            <img alt="Logo" src={LogoVendingSVC}/>
            <p className="svc-logo-font">SVC</p>
        </Link>
        <p className="svc-text-font mt-4 mb-5">
        <b>Solicita hoy mismo</b> una Máquina vending para
        <br/> 
        que todos los colaboradores puedan disfrutar de los mejores productos.
        </p>
      </div>
        <div className="container">
          <HubspotContactForm 
            region="na1"
            portalId="21901438"
            formId='7b84b863-7142-4009-ac6e-deb0515f6635'
          />
        </div> 
      <div className="form-footer mt-2">
      Al crear tu cuenta, estás aceptando los <span className='tc-font'>términos y condiciones</span>
      <br/>
      de Southern Vending Company - SVC
      </div>
      <div className='text-center mt-5'>
        <Link className='link-element-orange' to="/"><img alt="flecha-volver" src={flechaNaranja} className="me-2" />Volver al inicio</Link>
      </div>
      </div>
    </main> 
  );
}

export default SolicitarForm;