import './aboutUs.css'
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';

function AboutUs() {

  return (
  <>
    <Navbar/>
    <div className="container">
      <h1 className="about-title mx-3">Quiénes somos</h1>
      <div className="row mx-3">
        <div className="col-md-6 col-sm-12 content-container px-0">
          <p>
            Southern Vending Company, es una innovadora compañía conformada por jovenes 
            emprendedores que opera desde el 2016 en Chile, vendiendo productos bebestibles 
            fríos y calientes,  24/7 y en todo lugar mediante nuestras innovadoras y robustas 
            máquinas Vending.
          </p>
          <p>
            Tenemos la única Vending Machine del país capaz de soportar condiciones extremas, 
            mediante lo cual no requieres del uso de tu espacio construido, si no que extendemos y 
            creamos metros cuadrados para que puedas vender de manera automática.
          </p>
          <p>
            Actualmente nos encontramos ampliando nuestro negocio para que emprendedores 
            como nosotros puedan montar su negocio de vending machine, es por esto que hemos 
            creado la unidad de negocios de “franquicias”, donde podrás recibir todo el 
            conocimiento necesario, equipamiento y productos para que puedas generar ganancias 
            de manera autónoma y eficiente.
          </p>
        </div>
        <div className="col-md-6 col-sm-12 img-container px-0">
          {/* <h1>Imagen</h1> */}
        </div>
      </div>
    </div>
    <Footer/>
  </>
  );
}

export default AboutUs;