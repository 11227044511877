import './footer.css'
import LogoVendingSVC from '../../assets/img/LogoVendingSVC.svg'
import f from '../../assets/img/facebook.svg'
import t from '../../assets/img/twitter.svg'
import i from '../../assets/img/instagram.svg'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';

function Footer() {
    return (
        <div className="container footer-container">
        <footer className="px-4 row row-cols-1 row-cols-sm-2 row-cols-md-5 gx-4 py-5 my-5">
          <div className="col mb-3">
            <Link className="navbar-brand font-logo-footer p-0" to="/">
                <img src={LogoVendingSVC} alt="Logo" width={36} height={35} className="me-3"/>
                SVC
            </Link>
            <p className="font-footer mt-2">Chañarcillo 1201, Maipú. Chile</p>
          </div>
          <div className="col mb-3">
            <h5 className="subtitle-footer-font">Productos</h5>
            <ul className="nav flex-column font-footer">
              <li className="nav-item mb-2"><HashLink to="/#" className="nav-link p-0 text-muted">Franquiciado SVC</HashLink></li>
              <li className="nav-item mb-2"><HashLink to="/#beneficiosEmpresa" className="nav-link p-0 text-muted">Beneficio empresa</HashLink></li>
            </ul>
          </div>
          <div className="col mb-3">
            <h5 className="subtitle-footer-font">Quienes somos</h5>
            <ul className="nav flex-column font-footer">
              <li className="nav-item mb-2"><Link className="nav-link p-0 text-muted" to="/quienes-somos">Nuestra historia</Link></li>
            </ul>
          </div>
          <div className="col mb-3">
            <h5 className="subtitle-footer-font">Soporte</h5>
            <ul className="nav flex-column font-footer">
              <li className="nav-item mb-2"><Link className="nav-link p-0 text-muted" to="/form-reportar">Problemas en solicitud de información</Link></li>
              <li className="nav-item mb-2"><Link className="nav-link p-0 text-muted" to="/form-reportar">Problemas con pagos</Link></li>
              <li className="nav-item mb-2"><Link className="nav-link p-0 text-muted" to="/form-reportar">Problemas con la máquina</Link></li>
            </ul>
          </div>
          <div className="col mb-3">
            <div className="social-logo-f">
                <img src={f} alt="Logo"/>
            </div>
            <div className="social-logo">
                <img src={t} alt="Logo"/>
            </div>
            <div className="social-logo">
                <img src={i} alt="Logo"/>
            </div>
          </div>
        </footer>
      </div>
    );
}

export default Footer;