import './calculator.css'
import { useNavigate } from 'react-router-dom'

function formatearNumero(numeroStr) {
    const sinPuntos = numeroStr.replace(/\./g, '');
    return parseInt(sinPuntos, 10);
  }

function CalculatorTable({vDiarias,nMaquinas,rEsperada}) {

    if (vDiarias && typeof vDiarias === 'string') {
        vDiarias = formatearNumero(vDiarias);
    }
    if (nMaquinas && typeof nMaquinas === 'string') {
        nMaquinas = formatearNumero(nMaquinas);
    }
    if (rEsperada && typeof rEsperada === 'string') {
        rEsperada = formatearNumero(rEsperada);
    }

    const navigate = useNavigate();

    if(!vDiarias && !nMaquinas && !rEsperada){
        return null
    } else if (rEsperada) {
        const uAnual = rEsperada
        const pNetoProducto = 1092
        const pMargenFranquiciado = 0.85
        const vMargenFranquiciado = pNetoProducto*pMargenFranquiciado
        const uPorVender = uAnual/vMargenFranquiciado
        return (
        <>
            <h3 className="calculator-table-title mb-5">Resultado de tus ganancias</h3>
            <div className="Calculator-table">
                <div className="table-responsive">
                    <table className="table m-0">
                    <thead>
                        <tr>
                        <th scope="col">Unidades por vender</th>
                        <th scope="col">Maquinas necesarias</th>
                        <th scope="col">$ Utilidad deseada</th>
                        </tr>
                    </thead>
                    <tbody className="table-divider">
                        <tr>
                        <td>
                            {Math.round(uPorVender).toLocaleString("de-DE")}
                        </td>
                        <td>
                            {Math.round(uPorVender/(80*1*30))}
                        </td>
                        <td>
                            {`$${Number(uAnual).toLocaleString("de-DE")}`}
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
            <h3 className="rentabilidad-title">Tu rentabilidad promedio será de</h3>
            <h4 className="rentabilidad-number d-none d-lg-block">{`$${Number(uAnual).toLocaleString("de-DE")}`}</h4>
            <h4 className="rentabilidad-number-m d-lg-none">{`$${Number(uAnual).toLocaleString("de-DE")}`}</h4>
            <div className="text-center mt-4">
            <button className="btn btn-home-orange2" onClick={()=>navigate("form-solicitar")}>Lo quiero ahora</button>
            </div>
        </>
        );
    } else {
    
    const pCompra = 0.8
    const uVendidasXDia = vDiarias * nMaquinas * pCompra
    const pBrutoProducto = 1300
    const pNetoProducto = 1092
    const pMargenFranquiciado = 0.85
    const vMargenFranquiciado = pNetoProducto*pMargenFranquiciado
    const uDiaria = vMargenFranquiciado*uVendidasXDia*nMaquinas
    const uMensual = uDiaria*30

    // Añadidos 

    const ventasNetasMensuales = pNetoProducto * uVendidasXDia * 30
    const costoProducto = 363 * uVendidasXDia * 30
    const margenBruto = ventasNetasMensuales - costoProducto
    const gastosOperacionales = 151967
    const utilidadRepartir = margenBruto - gastosOperacionales
    const utilidadFranquiciado = utilidadRepartir * pMargenFranquiciado

    return (
        <>
            <h3 className="calculator-table-title mb-5">Resultado de tus ganancias</h3>
            <div className="Calculator-table">
                <div className="table-responsive">
                    <table className="table m-0">
                    <thead>
                        <tr>
                        <th scope="col">Nº de personas / día</th>
                        <th scope="col">Nº de máquinas</th>
                        <th scope="col">% de personas que compran</th>
                        <th scope="col">Unidades vendidas / día</th>
                        <th scope="col">Precio bruto del producto</th>
                        <th scope="col">Precio neto del producto (sin impuesto)</th>
                        <th scope="col">Ventas netas mensuales</th>
                        <th scope="col">Costo producto</th>
                        <th scope="col">Margen bruto</th>
                        <th scope="col">Gastos operacionales</th>
                        <th scope="col">Utilidad a repartir</th>
                        <th scope="col">% Utilidad franquiciado</th>
                        <th scope="col">Utilidad para franquiciado</th>
                        </tr>
                    </thead>
                    <tbody className="table-divider">
                        <tr>
                        <td>
                            {vDiarias}
                        </td>
                        <td>
                            {nMaquinas}
                        </td>
                        <td>
                            {`${pCompra*100}%`}
                        </td>
                        <td>
                            {uVendidasXDia.toFixed(1)}
                        </td>
                        <td>
                            {`$${pBrutoProducto.toLocaleString("de-DE")}`}
                        </td>
                        <td>
                            {`$${pNetoProducto.toLocaleString("de-DE")}`}
                        </td>
                        <td>
                            {`$${ventasNetasMensuales.toLocaleString("de-DE")}`}
                        </td>
                        <td>
                            {`$${costoProducto.toLocaleString("de-DE")}`}
                        </td>
                        <td>
                            {`$${margenBruto.toLocaleString("de-DE")}`}
                        </td>
                        <td>
                            {`$${gastosOperacionales.toLocaleString("de-DE")}`}
                        </td>
                        <td>
                            {`$${utilidadRepartir.toLocaleString("de-DE")}`}
                        </td>
                        <td>
                            {`${(pMargenFranquiciado*100).toLocaleString("de-DE")}%`}
                        </td>
                        <td>
                            {`$${Math.floor(utilidadFranquiciado).toLocaleString("de-DE")}`}
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
            <h3 className="rentabilidad-title">Tu rentabilidad promedio será de</h3>
            <h4 className="rentabilidad-number d-none d-lg-block">{`$${Math.floor(utilidadFranquiciado).toLocaleString("de-DE")}`}</h4>
            <h4 className="rentabilidad-number-m d-lg-none">{`$${Math.floor(utilidadFranquiciado).toLocaleString("de-DE")}`}</h4>
            <div className="text-center mt-4">
            <button className="btn btn-home-orange2" onClick={()=>navigate("form-solicitar")}>Lo quiero ahora</button>
        </div>
        </>
    );}
}

export default CalculatorTable;