import './navbar.css'
import LogoVendingSVC from '../../assets/img/LogoVendingSVC.svg'
import toggler from '../../assets/img/toggler.svg'
import { useNavigate, Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

function Navbar() {

    const navigate = useNavigate();

    return (
        <div className="Navbar">
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid d-flex justify-content-between mx-lg-5 px-lg-5">
                    <button className="navbar-toggler navbar-toggler-color" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <img alt="abrir menu" src={toggler} className="img-fluid"/>
                    </button>
                    <Link className="navbar-brand font-logo p-0 mx-auto" to="/">
                        <img src={LogoVendingSVC} alt="Logo" width="56" height="54" className="d-inline-block align-text-center me-3 img-navbar"/>
                        SVC
                    </Link>
                    <div className="navbar-text d-lg-none">
                        <button type="button" className="btn btn-orange py-1" onClick={()=>navigate("form-solicitar")}>Solicitar</button>
                    </div>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mb-2 mb-lg-0 d-flex align-items-center mx-auto">
                            <li className="nav-item">
                                <HashLink className="nav-link navbar-element" to="/#comoFunciona">Cómo funciona</HashLink>
                            </li>
                            <li className="nav-item">
                                <HashLink className="nav-link navbar-element" to="/#rentabilidad">Rentabilidad</HashLink>
                            </li>
                            <li className="nav-item">
                                <HashLink className="nav-link navbar-element" to="/#beneficiosEmpresa">Beneficio empresa</HashLink>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link navbar-element" to="/quienes-somos">Quienes somos</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link navbar-element" to="/form-solicitar">Contacto</Link>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link navbar-element-orange" href="#">Iniciar sesión</a>
                            </li> */}
                        </ul>
                    </div>
                    <div className="navbar-text d-none d-lg-block">
                        <button type="button" className="btn btn-orange py-1" onClick={()=>navigate("form-solicitar")}>Solicitar</button>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Navbar;